import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 0, 0); }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const scaleUp = keyframes`
  from { transform: scale(0.95); }
  to { transform: scale(1); }
`;

export const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

export const ChatWindow = styled.div`
  display: ${props => props.$isOpen ? 'flex' : 'none'};
  flex-direction: column;
  width: 300px;
  height: 600px;
  background: ${props => props.theme.backgroundColor};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  animation: ${fadeIn} 0.3s ease-out, ${scaleUp} 0.3s ease-in-out;
  backdrop-filter: blur(10px);
  background: ${props => props.theme.backgroundColor}CC;

  &:hover {
    box-shadow: 0 16px 40px rgba(0, 255, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    padding: 10px;
  }
`;

export const ChatHeader = styled.div`
  background: linear-gradient(to right, ${props => props.theme.primaryColor}, ${props => props.theme.primaryColorHover});
  color: ${props => props.theme.headerTextColor};
  padding: 15px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.borderColor};

  &:hover {
    background: linear-gradient(to right, ${props => props.theme.primaryColorHover}, ${props => props.theme.primaryColor});
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0));
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: translateX(100%);
  }
`;

export const ChatBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  scroll-behavior: smooth;
  background: ${props => props.theme.backgroundColor};
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.scrollbarColor};
    border-radius: 3px;
  }
`;

export const ChatForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: ${props => props.theme.backgroundColor};
  border-top: 1px solid ${props => props.theme.borderColor};
`;

export const ChatInput = styled.input`
  margin-bottom: 10px;
  padding: 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 8px;
  background-color: ${props => props.theme.inputBackgroundColor};
  color: ${props => props.theme.inputTextColor};
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.focusColor};
    border-color: ${props => props.theme.primaryColor};
    transform: translateY(-2px);
  }
`;

export const ChatTextarea = styled.textarea`
  margin-bottom: 10px;
  padding: 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 8px;
  background-color: ${props => props.theme.inputBackgroundColor};
  color: ${props => props.theme.inputTextColor};
  resize: none;
  height: 80px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.focusColor};
    border-color: ${props => props.theme.primaryColor};
    transform: translateY(-2px);
  }
`;

export const ChatButton = styled.button`
  background: linear-gradient(to right, ${props => props.theme.primaryColor}, ${props => props.theme.primaryColorHover});
  color: ${props => props.theme.buttonTextColor};
  border: none;
  padding: 12px 18px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.focusColor};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0));
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: translateX(100%);
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ChatbotButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(to right, ${props => props.theme.primaryColor}, ${props => props.theme.primaryColorHover});
  color: ${props => props.theme.buttonTextColor};
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1001;
  animation: ${pulseAnimation} 2s infinite;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.focusColor}, 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const MessageBubble = styled.div`
  max-width: 80%;
  padding: 12px 18px;
  border-radius: 18px;
  margin-bottom: 10px;
  word-wrap: break-word;
  background: ${props => props.$isUser ? 
    `linear-gradient(to right, ${props.theme.primaryColor}, ${props.theme.primaryColorHover})` : 
    props.theme.botMessageBackground};
  color: ${props => props.$isUser ? props.theme.userMessageText : props.theme.botMessageText};
  align-self: ${props => props.$isUser ? 'flex-end' : 'flex-start'};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.3s ease-out;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
`;

export const TypingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin-bottom: 10px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.typingIndicatorColor};
    margin: 0 2px;
    animation: bounce 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }
`;

export const ErrorMessage = styled.span`
  color: #ff4444;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 8px;
  display: block;
  animation: ${fadeIn} 0.3s ease-out;
`;

export const QuickReplyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  background: ${props => props.theme.backgroundColor};
`;

export const QuickReplyButton = styled.button`
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.buttonTextColor};
  border: none;
  padding: 8px 12px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background: ${props => props.theme.primaryColorHover};
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const MessageTimestamp = styled.span`
  font-size: 0.75rem;
  color: ${props => props.theme.timestampColor};
  margin-left: 5px;
  opacity: 0.7;
`;

export const ClearChatButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.headerTextColor};
  font-size: 0.8rem;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.focusColor};
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const AnimatedMessageBubble = motion(MessageBubble);

export const AnimatedQuickReplyButton = motion(QuickReplyButton);
